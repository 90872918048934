import {z} from 'zod';

export const ClientID = z.string();
export type ClientID = z.infer<typeof ClientID>;
export const OriginID = z.string();
export type OriginID = z.infer<typeof OriginID>;
export const Origin = z.string();
export type Origin = z.infer<typeof Origin>;

export const Client = z
  .object({
    name: z.string(),
    contactName: z.string(),
    email: z.string().email(),
    note: z.string(),
  })
  .partial();
export type Client = z.infer<typeof Client>;
export const NewClient = z
  .object({
    name: z.string(),
    contactName: z.string(),
    email: z.string().email(),
    note: z.string(),
    origins: z.array(Origin),
  })
  .partial();
export type NewClient = z.infer<typeof NewClient>;

export const ItemActions = z.enum(['UPDATE', 'DELETE', 'NEW']);
export type ItemActions = z.infer<typeof ItemActions>;

export const ChangeClientItem = z.tuple([ClientID, Client, ItemActions]);
export type ChangeClientItem = z.infer<typeof ChangeClientItem>;
export const parseChangeClientItem = (
  change: unknown
): change is ChangeClientItem => ChangeClientItem.safeParse(change).success;
export const ChangeOriginItem = z.tuple([
  ClientID,
  OriginID,
  Origin,
  ItemActions,
]);
export type ChangeOriginItem = z.infer<typeof ChangeOriginItem>;
export const parseChangeOriginItem = (
  change: unknown
): change is ChangeOriginItem => ChangeOriginItem.safeParse(change).success;
export const ChangeNewClientItem = z.tuple([ClientID, ItemActions]);
export type ChangeNewClientItem = z.infer<typeof ChangeNewClientItem>;
export const parseChangeNewClientItem = (
  change: unknown
): change is ChangeNewClientItem =>
  ChangeNewClientItem.safeParse(change).success;

export const UpdateItemsValue = z.object({
  clientChanges: Client,
  originChanges: z.map(OriginID, Origin),
});
export type UpdateItemsValue = z.infer<typeof UpdateItemsValue>;
export const UpdateItems = z.map(ClientID, UpdateItemsValue);
export type UpdateItems = z.infer<typeof UpdateItems>;

export const DeleteItems = z.object({
  clientChanges: z.array(ClientID),
  originChanges: z.array(OriginID),
});
export type DeleteItems = z.infer<typeof DeleteItems>;

export const NewItemsValue = z.object({
  clientChanges: Client,
  originChanges: z.map(OriginID, Origin),
});
export type NewItemsValue = z.infer<typeof NewItemsValue>;
export const NewItems = z.map(ClientID, NewItemsValue);
export type NewItems = z.infer<typeof NewItems>;

export const ClientList = z.record(
  z.object({
    name: z.string(),
    contactName: z.string(),
    email: z.string().email(),
    note: z.string(),
    origins: z.record(Origin),
  })
);
export type ClientList = z.infer<typeof ClientList>;

export const ClientListPartial = z.record(
  z
    .object({
      name: z.string(),
      contactName: z.string(),
      email: z.string().email(),
      note: z.string(),
      origins: z.record(Origin),
    })
    .partial()
);
export type ClientListPartial = z.infer<typeof ClientListPartial>;

export const NewOrigins = z.map(ClientID, z.map(OriginID, Origin));
export type NewOrigins = z.infer<typeof NewOrigins>;
export const NewOriginRequest = z.object({
  id: ClientID,
  origins: z.array(Origin),
});
export type NewOriginRequest = z.infer<typeof NewOriginRequest>;
