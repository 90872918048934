import create from 'zustand';
import {
  ChangeClientItem,
  ChangeNewClientItem,
  ChangeOriginItem,
  ClientID,
  ClientList,
  DeleteItems,
  ItemActions,
  NewItems,
  NewItemsValue,
  NewOrigins,
  Origin,
  OriginID,
  parseChangeClientItem,
  parseChangeNewClientItem,
  parseChangeOriginItem,
  UpdateItems,
  UpdateItemsValue,
} from '../types/data';
import {nanoid} from 'nanoid';
import {
  commitClientAdditions,
  commitClientDeletion,
  commitClientUpdates,
} from '../features/clients';
import {commitOriginAdditions, commitOriginDeletion} from '../features/origins';

interface ClientStore {
  clientList: ClientList;
  setClientList: (clientList: ClientList) => void;
  clientListCurrent: ClientList;
  setClientListCurrent: (clientListCurrent: ClientList) => void;
  refreshClientList: number;
  editMode: boolean;
  toggleEditMode: () => void;
  clientListUpdates: UpdateItems;
  clientListDeletions: DeleteItems;
  clientListAdditions: NewItems;
  originAdditions: NewOrigins;
  addClientChange: (
    change: ChangeClientItem | ChangeOriginItem | ChangeNewClientItem
  ) => void;
  addNewClient: () => void;
  addNewOrigin: (clientID: ClientID) => void;
  finishEdits: () => void;
  revertEdits: () => void;
  clearEdits: () => void;
  authToken: string;
  setAuthToken: (authToken: string) => void;
}

const useStoreClients = create<ClientStore>((set, get) => ({
  clientList: {},
  setClientList: clientList => set({clientList}),
  clientListCurrent: {},
  setClientListCurrent: clientListCurrent => set({clientListCurrent}),

  refreshClientList: 0,

  editMode: false,
  toggleEditMode: () => set({editMode: !get().editMode}),

  clientListUpdates: new Map<ClientID, UpdateItemsValue>(),
  clientListDeletions: {clientChanges: [], originChanges: []},
  clientListAdditions: new Map<ClientID, NewItemsValue>(),
  originAdditions: new Map<ClientID, Map<OriginID, Origin>>(),

  addClientChange: change => {
    const {
      clientListUpdates,
      clientListDeletions,
      clientListAdditions,
      clientListCurrent,
      clientList,
      originAdditions,
    } = get();
    const updateClientChange: UpdateItemsValue = {
      clientChanges: {},
      originChanges: new Map<OriginID, Origin>(),
    };
    const newClientChange: NewItemsValue = {
      clientChanges: {},
      originChanges: new Map<OriginID, Origin>(),
    };
    const newOriginChange = new Map<OriginID, Origin>();

    if (parseChangeClientItem(change)) {
      const addToNewClient =
        Object.keys(clientListCurrent).indexOf(change[0]) === -1;

      if (change[2] === ItemActions.enum.UPDATE && addToNewClient) {
        const currentChange =
          clientListAdditions.get(change[0]) || newClientChange;
        const mergeChanges = {
          ...currentChange,
          clientChanges: {
            ...currentChange?.clientChanges,
            ...change[1],
          },
        };

        clientListAdditions.set(change[0], mergeChanges);
        set({clientListAdditions});
      }

      if (change[2] === ItemActions.enum.UPDATE && !addToNewClient) {
        const currentChange =
          clientListUpdates.get(change[0]) || updateClientChange;
        const mergeChanges = {
          ...currentChange,
          clientChanges: {
            ...currentChange?.clientChanges,
            ...change[1],
          },
        };

        clientListUpdates.set(change[0], mergeChanges);
        set({clientListUpdates});
      }

      if (change[2] === ItemActions.enum.DELETE) {
        if (clientListDeletions.clientChanges.indexOf(change[0]) === -1) {
          const clients = {...clientList};

          delete clients[change[0]];

          set({
            clientListDeletions: {
              ...clientListDeletions,
              clientChanges: clientListDeletions.clientChanges.concat(
                change[0]
              ),
            },
            clientList: clients,
          });
        }
      }
    }

    if (parseChangeOriginItem(change)) {
      const addToNewClient =
        Object.keys(clientListCurrent).indexOf(change[0]) === -1;
      const isNewOrigin =
        !addToNewClient &&
        Object.keys(clientListCurrent[change[0]].origins).indexOf(change[1]) ===
          -1;

      if (change[3] === ItemActions.enum.UPDATE && addToNewClient) {
        const currentChange =
          clientListAdditions.get(change[0]) || newClientChange;
        currentChange.originChanges.set(change[1], change[2]);

        clientListAdditions.set(change[0], currentChange);
        set({clientListAdditions});
      }

      if (
        change[3] === ItemActions.enum.UPDATE &&
        !addToNewClient &&
        !isNewOrigin
      ) {
        const currentChange =
          clientListUpdates.get(change[0]) || updateClientChange;
        currentChange.originChanges.set(change[1], change[2]);

        clientListUpdates.set(change[0], currentChange);
        set({clientListUpdates});
      }

      if (
        change[3] === ItemActions.enum.UPDATE &&
        !addToNewClient &&
        isNewOrigin
      ) {
        const currentChange = originAdditions.get(change[0]) || newOriginChange;
        currentChange.set(change[1], change[2]);

        originAdditions.set(change[0], currentChange);
        set({originAdditions});
      }

      if (change[3] === ItemActions.enum.DELETE) {
        if (clientListDeletions.originChanges.indexOf(change[1]) === -1) {
          const clients = {...clientList};

          delete clients[change[0]].origins[change[1]];

          set({
            clientListDeletions: {
              ...clientListDeletions,
              originChanges: clientListDeletions.originChanges.concat(
                change[1]
              ),
            },
            clientList: clients,
          });
        }
      }
    }

    if (parseChangeNewClientItem(change)) {
      if (change[1] === ItemActions.enum.NEW) {
        clientListAdditions.set(change[0], newClientChange);
        set({clientListAdditions});
      }
    }
  },
  addNewClient: () => {
    const {clientList, addClientChange} = get();

    const tempID: ClientID = nanoid();

    set({
      clientList: {
        ...clientList,
        [tempID]: {
          name: '',
          contactName: '',
          email: '',
          note: '',
          origins: {},
        },
      },
    });

    addClientChange([tempID, ItemActions.enum.NEW]);
  },
  addNewOrigin: (clientID: ClientID) => {
    const {clientList} = get();

    const tempID: OriginID = nanoid();

    set({
      clientList: {
        ...clientList,
        [clientID]: {
          ...clientList[clientID],
          origins: {
            ...clientList[clientID].origins,
            [tempID]: '',
          },
        },
      },
    });
  },
  finishEdits: () => {
    const {
      clientListUpdates,
      clientListDeletions,
      clientListAdditions,
      originAdditions,
      toggleEditMode,
      revertEdits,
      refreshClientList,
      clearEdits,
      authToken,
    } = get();
    const apiActions = [];

    if (clientListUpdates.size > 0) {
      apiActions.push(commitClientUpdates(clientListUpdates, authToken));
    }

    if (clientListDeletions.clientChanges.length > 0) {
      apiActions.push(
        commitClientDeletion(clientListDeletions.clientChanges, authToken)
      );
    }

    if (clientListDeletions.originChanges.length > 0) {
      apiActions.push(
        commitOriginDeletion(clientListDeletions.originChanges, authToken)
      );
    }

    if (clientListAdditions.size > 0) {
      apiActions.push(commitClientAdditions(clientListAdditions, authToken));
    }

    if (originAdditions.size > 0) {
      apiActions.push(commitOriginAdditions(originAdditions, authToken));
    }

    Promise.all(apiActions).then(
      () => {
        toggleEditMode();
        clearEdits();
        set({refreshClientList: refreshClientList + 1});
      },
      () => {
        revertEdits();
      }
    );
  },
  revertEdits: () => {
    const {clientListCurrent, clearEdits} = get();

    clearEdits();

    set({
      clientList: clientListCurrent,
    });
  },
  clearEdits: () => {
    const {clientListUpdates, clientListAdditions, originAdditions} = get();

    clientListUpdates.clear();
    clientListAdditions.clear();
    originAdditions.clear();

    set({
      clientListUpdates,
      clientListDeletions: {clientChanges: [], originChanges: []},
      clientListAdditions,
      originAdditions,
    });
  },
  authToken: '',
  setAuthToken: authToken => set({authToken}),
}));

export {useStoreClients};
