import {NewOriginRequest, NewOrigins, OriginID} from '../types/data';
import {addOrigins, deleteOrigins} from '../services/api';

export function commitOriginAdditions(
  originAdditions: NewOrigins,
  authToken: string
) {
  const req: NewOriginRequest[] = [];
  for (const [clientID, origins] of originAdditions) {
    const newOrigin: NewOriginRequest = {
      id: clientID,
      origins: [],
    };

    for (const origin of origins.values()) {
      newOrigin.origins.push(origin);
    }

    req.push(newOrigin);
  }

  return addOrigins(req, authToken);
}

export function commitOriginDeletion(originIDs: OriginID[], authToken: string) {
  return deleteOrigins(originIDs, authToken);
}
