import create from 'zustand';
import {lightTheme} from '../styles/themes';
import {DefaultTheme} from 'styled-components';

interface UIStore {
  theme: DefaultTheme;
  setTheme: (theme: DefaultTheme) => void;
}

const useUIStore = create<UIStore>(set => ({
  theme: lightTheme,
  setTheme: theme => set({theme}),
}));

export {useUIStore};
