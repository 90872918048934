import * as React from 'react';
import styled from 'styled-components';
import {StaticImage} from 'gatsby-plugin-image';
import {graphql, useStaticQuery} from 'gatsby';

type SchemaGetTitle = {
  site: {
    siteMetadata: {
      headerTitle: string;
    };
  };
};

const Header: React.FC = () => {
  const queryGetTitle = useStaticQuery<SchemaGetTitle>(
    graphql`
      query {
        site {
          siteMetadata {
            headerTitle
          }
        }
      }
    `
  );

  return (
    <HeaderElement>
      <StaticImage
        alt="AccessAngel Logo"
        src="../images/aa_logo.png"
        height={64}
      />
      <HeaderTitle>{queryGetTitle.site.siteMetadata.headerTitle}</HeaderTitle>
    </HeaderElement>
  );
};

const HeaderElement = styled.header`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.sizeScale.sBase * 3}px;
  padding: ${props => props.theme.sizeScale.sBase * 2}px;
  background: ${props => props.theme.colours.violet2};
  border-bottom: 1px solid ${props => props.theme.colours.mauve6};
  height: ${props => props.theme.sizeScale.sBase * 12}px;
`;

const HeaderTitle = styled.a`
  font-size: ${props => props.theme.sizeScale.sBase * 4}px;
  font-family: ${props => props.theme.fonts.font1};
  font-weight: 500;
`;

export default Header;
