import styled, {css} from 'styled-components';

const ClientsTable = styled.table`
  display: grid;
  flex: 1;
  width: auto;
  border-collapse: collapse;
  grid-template-columns: repeat(5, 2fr) 1fr;
`;

const ClientsTableHeader = styled.thead`
  display: contents;
`;

const ClientsTableBody = styled.tbody`
  display: contents;
`;

const ClientsTableRow = styled.tr`
  display: contents;

  &:nth-child(even) td {
    background: ${props => props.theme.colours.violet2};
  }

  &:last-child td {
    border-bottom: 1px solid ${props => props.theme.colours.mauve6};
  }

  &:last-of-type td:first-of-type {
    border-bottom-left-radius: ${props => props.theme.sizeScale.sBase * 0.5}px;
  }

  &:last-of-type td:last-of-type {
    border-bottom-right-radius: ${props => props.theme.sizeScale.sBase * 0.5}px;
  }
`;

const ClientTableItem = css`
  padding: ${props => props.theme.sizeScale.sBase * 2}px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: 1px solid ${props => props.theme.colours.mauveA6};
  display: flex;
  flex-direction: column;
`;

const ClientTableHead = styled.th`
  text-align: left;
  font-weight: 600;
  background: ${props => props.theme.colours.mauve12};
  color: white;

  &:first-of-type {
    border-top-left-radius: ${props => props.theme.sizeScale.sBase * 0.5}px;
  }

  &:last-of-type {
    border-top-right-radius: ${props => props.theme.sizeScale.sBase * 0.5}px;
    background: ${props => props.theme.colours.purple12};
  }

  ${ClientTableItem}
`;

const ClientTableData = styled.td`
  &:first-child {
    border-left: 1px solid ${props => props.theme.colours.mauve6};
  }

  &:last-child {
    border-right: 1px solid ${props => props.theme.colours.mauve6};
  }

  ${ClientTableItem}
`;

const ClientInputItem = css`
  border: 2px solid ${props => props.theme.colours.mauve7};
  border-radius: ${props => props.theme.sizeScale.sBase * 0.5}px;
  color: ${props => props.theme.colours.purple12};

  &:focus {
    border: 2px solid ${props => props.theme.colours.indigo9};
    outline: none;
  }
`;

const ClientTableOriginContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.sizeScale.sBase}px;

  & input {
    padding: ${props => props.theme.sizeScale.sBase}px
      ${props => props.theme.sizeScale.sBase * 9}px
      ${props => props.theme.sizeScale.sBase}px
      ${props => props.theme.sizeScale.sBase}px;
  }
`;

const ClientTableInput = styled.input`
  padding: ${props => props.theme.sizeScale.sBase}px;
  width: 100%;

  ${ClientInputItem}
`;

const ClientTableInputButton = styled.button`
  position: absolute;
  transform: translateX(-${props => props.theme.sizeScale.sBase}px);
  background: none;
  border: none;
  outline: none;
  color: ${props => props.theme.colours.red11};
  font-weight: 500;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colours.red12};
  }
`;

const ClientTableTextarea = styled.textarea`
  padding: ${props => props.theme.sizeScale.sBase}px;
  min-height: ${props => props.theme.sizeScale.sBase * 20}px;
  resize: vertical;

  ${ClientInputItem}
`;

const ClientTableOrigin = styled.a`
  align-self: flex-start;
  padding: ${props => props.theme.sizeScale.sBase}px
    ${props => props.theme.sizeScale.sBase * 1.5}px;
  margin-bottom: ${props => props.theme.sizeScale.sBase}px;
  background: ${props => props.theme.colours.indigo4};
  color: ${props => props.theme.colours.indigo9};
  text-decoration: none;
  font-weight: 500;
  border: 1px solid ${props => props.theme.colours.indigo6};
  border-radius: ${props => props.theme.sizeScale.sBase * 0.5}px;

  &:visited {
    color: ${props => props.theme.colours.indigo9};
  }

  &:hover {
    color: ${props => props.theme.colours.indigo12};
    cursor: pointer;
  }
`;

export {
  ClientsTable,
  ClientsTableBody,
  ClientsTableHeader,
  ClientsTableRow,
  ClientTableData,
  ClientTableHead,
  ClientTableInput,
  ClientTableTextarea,
  ClientTableOrigin,
  ClientTableInputButton,
  ClientTableOriginContainer,
};
