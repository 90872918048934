import {
  green,
  indigo,
  mauve,
  mauveA,
  purple,
  red,
  violet,
  violetA,
} from '@radix-ui/colors';
import {DefaultTheme} from 'styled-components';

const sizeScale = {
  sBase: 8,
  bBase: 4,
};

const fonts = {
  font1: '"Rajdhani", sans-serif',
  font2: '"Quicksand", sans-serif',
};

const lightTheme: DefaultTheme = {
  colours: {
    ...mauve,
    ...mauveA,
    ...violet,
    ...violetA,
    ...purple,
    ...red,
    ...indigo,
    ...green,
  },
  fonts,
  sizeScale,
};

export {lightTheme};
