import {useAuth0} from '@auth0/auth0-react';
import * as React from 'react';
import shallow from 'zustand/shallow';
import {logAAError} from '../log/error';
import {useStoreClients} from '../store/clients';

const isBrowser = typeof window !== 'undefined';

export const AuthRequired: React.FC = ({children}) => {
  const {isLoading, isAuthenticated, error, loginWithRedirect} = useAuth0();

  if (!isBrowser || isLoading) {
    return <div></div>;
  }

  if (error) {
    logAAError('auth0 error', error);

    return <div></div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();

    return <div></div>;
  }

  return <>{children}</>;
};
