import * as React from 'react';
import styled from 'styled-components';
import {StaticImage} from 'gatsby-plugin-image';

const Footer: React.FC = () => (
  <FooterContainer>
    <FooterElements>
      <CompanyFigure>
        <StaticImage
          alt="AccessAngel Logo"
          src="../images/aa_logo_word.png"
          width={160}
        />
        <CompanyDetails>
          <CompanyDetailsInfo>View Your Content. Their Way.</CompanyDetailsInfo>
          <CompanyDetailsName>Hands Free Computing Ltd.</CompanyDetailsName>
        </CompanyDetails>
      </CompanyFigure>
      <FooterNavsContainer>
        <FooterNavContainer>
          <FooterNavTitle>Pages</FooterNavTitle>
          <FooterNav>
            <FooterNavLink href="/">Clients</FooterNavLink>
          </FooterNav>
        </FooterNavContainer>
        <FooterNavContainer>
          <FooterNavTitle>Help</FooterNavTitle>
          <FooterNav>
            <FooterNavLink />
          </FooterNav>
        </FooterNavContainer>
      </FooterNavsContainer>
    </FooterElements>
  </FooterContainer>
);

const FooterContainer = styled.footer`
  display: flex;
  flex-grow: 1;
  background: ${props => props.theme.colours.violet2};
  padding: ${props => props.theme.sizeScale.sBase * 8}px
    ${props => props.theme.sizeScale.sBase * 10}px;
  border-top: 1px solid ${props => props.theme.colours.mauve6};
`;

const FooterElements = styled.div`
  display: flex;
  align-self: flex-start;
  width: 100%;
  justify-content: space-around;
`;

const CompanyFigure = styled.figure`
  display: block;
`;

const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.sizeScale.sBase * 2}px;
  margin: ${props => props.theme.sizeScale.sBase}px 0;
`;

const CompanyDetailsInfo = styled.figcaption`
  font-weight: 600;
`;

const CompanyDetailsName = styled.span`
  font-size: ${props => props.theme.sizeScale.sBase * 2}px;
`;

const FooterNavsContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.sizeScale.sBase * 16}px;
`;

const FooterNavContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterNavTitle = styled.h2`
  font-weight: 400;
  font-family: ${props => props.theme.fonts.font1};
`;

const FooterNav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const FooterNavLink = styled.a`
  font-weight: 600;
  margin: ${props => props.theme.sizeScale.sBase}px 0;
  color: ${props => props.theme.colours.indigo12};
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colours.indigo11};
  }
`;

export default Footer;
