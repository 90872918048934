import {
  ClientID,
  ClientListPartial,
  NewClient,
  NewItems,
  UpdateItems,
} from '../types/data';
import {addClients, deleteClients, updateClients} from '../services/api';

export function commitClientUpdates(
  clientListUpdates: UpdateItems,
  authToken: string
) {
  let req: ClientListPartial = {};
  for (const [clientID, changes] of clientListUpdates) {
    for (const [originID, origin] of changes.originChanges) {
      req = {
        ...req,
        [clientID]: {
          origins: {
            ...req[clientID]?.origins,
            [originID]: origin,
          },
        },
      };
    }
    req = {
      ...req,
      [clientID]: {
        ...req[clientID],
        ...changes.clientChanges,
      },
    };
  }

  return updateClients(req, authToken);
}

export function commitClientDeletion(clientIDs: ClientID[], authToken: string) {
  return deleteClients(clientIDs, authToken);
}

export function commitClientAdditions(
  clientsAdditions: NewItems,
  authToken: string
) {
  const req: NewClient[] = [];
  for (const changes of clientsAdditions.values()) {
    const clientObj: NewClient = {
      ...changes.clientChanges,
      origins: [],
    };

    for (const origin of changes.originChanges.values()) {
      clientObj.origins?.push(origin);
    }

    req.push(clientObj);
  }

  return addClients(req, authToken);
}
