import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {getClients} from '../services/api';
import {useStoreClients} from '../store/clients';
import shallow from 'zustand/shallow';
import styled, {ThemeProvider} from 'styled-components';
import {useUIStore} from '../store/ui';
import {
  ClientsTable,
  ClientsTableBody,
  ClientsTableHeader,
  ClientsTableRow,
  ClientTableData,
  ClientTableHead,
  ClientTableInput,
  ClientTableInputButton,
  ClientTableOrigin,
  ClientTableOriginContainer,
  ClientTableTextarea,
} from '../components/table';
import {
  ActionButton,
  DestructiveActionButton,
  PositiveActionButton,
  SecondaryActionButton,
} from '../components/button';
import {ChangeClientItem, ChangeOriginItem, ItemActions} from '../types/data';
import Modal from 'react-modal';
import {AAInstructions} from '../components/instructions';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {AuthRequired} from '../components/auth';

const Clients: React.FC = () => {
  const [
    clientList,
    setClientList,
    setClientListCurrent,
    refreshClientList,
    setAuthToken,
  ] = useStoreClients(
    state => [
      state.clientList,
      state.setClientList,
      state.setClientListCurrent,
      state.refreshClientList,
      state.setAuthToken,
    ],
    shallow
  );
  const clientEntries = Object.entries(clientList);
  const audience = process.env.GATSBY_AUTH_AUDIENCE;
  const {getAccessTokenSilently} = useAuth0();

  React.useEffect(() => {
    getAccessTokenSilently({audience}).then(token => {
      setAuthToken(token);
      getClients(token).then(
        res => {
          setClientListCurrent(res);
          setClientList(res);
        },
        () => {}
      );
    });
  }, [refreshClientList]);

  const [editMode, toggleEditMode] = useStoreClients(
    state => [state.editMode, state.toggleEditMode],
    shallow
  );
  const theme = useUIStore(state => state.theme);

  const addClientChange = useStoreClients(state => state.addClientChange);
  const handleClientChange = (clientChange: ChangeClientItem) => {
    setClientList({
      ...clientList,
      [clientChange[0]]: {
        ...clientList[clientChange[0]],
        ...clientChange[1],
      },
    });

    addClientChange(clientChange);
  };
  const handleOriginChange = (originChange: ChangeOriginItem) => {
    setClientList({
      ...clientList,
      [originChange[0]]: {
        ...clientList[originChange[0]],
        origins: {
          ...clientList[originChange[0]].origins,
          [originChange[1]]: originChange[2],
        },
      },
    });

    addClientChange(originChange);
  };

  const [finishEdits, addNewClient, addNewOrigin] = useStoreClients(
    state => [state.finishEdits, state.addNewClient, state.addNewOrigin],
    shallow
  );

  const [instructionsOpen, setInstructionsOpen] = React.useState(false);
  const [instructionsID, setInstructionsID] = React.useState('');
  const [instructionsName, setInstructionsName] = React.useState('');
  const openInstructions = (id: string, name: string) => {
    setInstructionsID(id);
    setInstructionsName(name);
    setInstructionsOpen(true);
  };
  const closeInstructions = () => setInstructionsOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Clients" />
        <Modal isOpen={instructionsOpen} onRequestClose={closeInstructions}>
          <AAInstructions
            clientID={instructionsID}
            clientName={instructionsName}
            closeInstructions={closeInstructions}
          />
        </Modal>
        <ClientsTableActionRow>
          {editMode ? (
            <ClientsTableActionRowButtonGroup>
              <ActionButton onClick={finishEdits}>Finished</ActionButton>
            </ClientsTableActionRowButtonGroup>
          ) : (
            <ActionButton onClick={toggleEditMode}>Edit</ActionButton>
          )}
        </ClientsTableActionRow>
        <ClientsTableContainer>
          <ClientsTable>
            <ClientsTableHeader>
              <ClientsTableRow>
                <ClientTableHead>Name</ClientTableHead>
                <ClientTableHead>Contact's Name</ClientTableHead>
                <ClientTableHead>Contact's Email</ClientTableHead>
                <ClientTableHead>Websites</ClientTableHead>
                <ClientTableHead>Note</ClientTableHead>
                <ClientTableHead>Options</ClientTableHead>
              </ClientsTableRow>
            </ClientsTableHeader>
            <ClientsTableBody>
              {editMode
                ? clientEntries.map(client => (
                    <ClientsTableRow key={client[0]}>
                      <ClientTableData>
                        <ClientTableInput
                          value={client[1].name}
                          placeholder="Name"
                          onChange={event =>
                            handleClientChange([
                              client[0],
                              {name: event.target.value},
                              ItemActions.enum.UPDATE,
                            ])
                          }
                        />
                      </ClientTableData>
                      <ClientTableData>
                        <ClientTableInput
                          value={client[1].contactName}
                          placeholder="Contact Name"
                          onChange={event =>
                            handleClientChange([
                              client[0],
                              {contactName: event.target.value},
                              ItemActions.enum.UPDATE,
                            ])
                          }
                        />
                      </ClientTableData>
                      <ClientTableData>
                        <ClientTableInput
                          value={client[1].email}
                          placeholder="Email"
                          type="email"
                          onChange={event =>
                            handleClientChange([
                              client[0],
                              {email: event.target.value},
                              ItemActions.enum.UPDATE,
                            ])
                          }
                        />
                      </ClientTableData>
                      <ClientTableData>
                        {Object.entries(client[1].origins).map(origin => (
                          <ClientTableOriginContainer key={origin[0]}>
                            <ClientTableInput
                              value={origin[1]}
                              onChange={event =>
                                handleOriginChange([
                                  client[0],
                                  origin[0],
                                  event.target.value,
                                  ItemActions.enum.UPDATE,
                                ])
                              }
                            />
                            <ClientTableInputButton
                              onClick={() => {
                                addClientChange([
                                  client[0],
                                  origin[0],
                                  '',
                                  ItemActions.enum.DELETE,
                                ]);
                              }}
                            >
                              Delete
                            </ClientTableInputButton>
                          </ClientTableOriginContainer>
                        ))}
                        <PositiveActionButton
                          onClick={() => addNewOrigin(client[0])}
                        >
                          Add Website
                        </PositiveActionButton>
                      </ClientTableData>
                      <ClientTableData>
                        <ClientTableTextarea
                          value={client[1].note}
                          placeholder="Note"
                          onChange={event =>
                            handleClientChange([
                              client[0],
                              {note: event.target.value},
                              ItemActions.enum.UPDATE,
                            ])
                          }
                        />
                      </ClientTableData>
                      <ClientTableData>
                        <DestructiveActionButton
                          onClick={() => {
                            addClientChange([
                              client[0],
                              {},
                              ItemActions.enum.DELETE,
                            ]);
                          }}
                        >
                          Delete Client
                        </DestructiveActionButton>
                      </ClientTableData>
                    </ClientsTableRow>
                  ))
                : clientEntries.map(client => (
                    <ClientsTableRow key={client[0]}>
                      <ClientTableData>{client[1].name}</ClientTableData>
                      <ClientTableData>{client[1].contactName}</ClientTableData>
                      <ClientTableData>{client[1].email}</ClientTableData>
                      <ClientTableData>
                        {Object.entries(client[1].origins).map(origin => (
                          <ClientTableOrigin
                            key={origin[0]}
                            href={encodeURI(`https://${origin[1]}`)}
                          >
                            {origin[1]}
                          </ClientTableOrigin>
                        ))}
                      </ClientTableData>
                      <ClientTableData>{client[1].note}</ClientTableData>
                      <ClientTableData>
                        <SecondaryActionButton
                          onClick={() =>
                            openInstructions(client[0], client[1].name)
                          }
                        >
                          Show Instructions
                        </SecondaryActionButton>
                      </ClientTableData>
                    </ClientsTableRow>
                  ))}
              {editMode && (
                <ClientsTableRow>
                  <ClientTableData />
                  <ClientTableData />
                  <ClientTableData />
                  <ClientTableData />
                  <ClientTableData />
                  <ClientTableData>
                    <PositiveActionButton onClick={() => addNewClient()}>
                      Add Client
                    </PositiveActionButton>
                  </ClientTableData>
                </ClientsTableRow>
              )}
            </ClientsTableBody>
          </ClientsTable>
        </ClientsTableContainer>
        <ClientsTableActionRow>
          {editMode && (
            <ClientsTableActionRowButtonGroup>
              <ActionButton onClick={finishEdits}>Finished</ActionButton>
            </ClientsTableActionRowButtonGroup>
          )}
        </ClientsTableActionRow>
      </Layout>
    </ThemeProvider>
  );
};

const ClientsTableContainer = styled.div`
  margin: ${props => props.theme.sizeScale.sBase * 4}px
    ${props => props.theme.sizeScale.sBase * 4}px
    ${props => props.theme.sizeScale.sBase * 6}px;
`;

const ClientsTableActionRow = styled.div`
  margin: ${props => props.theme.sizeScale.sBase * 4}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ClientsTableActionRowButtonGroup = styled.div`
  margin-left: ${props => props.theme.sizeScale.sBase * 4}px;
`;

const authWrap: React.FC = () => {
  const domainURL = process.env.GATSBY_AUTH_DOMAIN_URL || '';
  const clientID = process.env.GATSBY_AUTH_CLIENT_ID || '';
  const redirectURL = process.env.GATSBY_AUTH_REDIRECT_URL || '';
  const audience = process.env.GATSBY_AUTH_AUDIENCE || '';

  return (
    <Auth0Provider
      domain={domainURL}
      clientId={clientID}
      redirectUri={redirectURL}
      audience={audience}
    >
      <AuthRequired>
        <Clients />
      </AuthRequired>
    </Auth0Provider>
  );
};

export default authWrap;
