import {logAAError} from '../log/error';

function doRequest(link: RequestInfo, req: RequestInit) {
  return fetch(link, req).catch(err => {
    logAAError(`failed fetching: ${String(err)}`);
  });
}

async function handleRequest(link: RequestInfo, req: RequestInit) {
  const headers = {
    'Content-Type': 'application/json',
  };

  return doRequest(link, {headers, ...req});
}

export {handleRequest};
