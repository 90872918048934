import {handleRequest} from './fetch';
import {
  ClientID,
  ClientList,
  ClientListPartial,
  NewClient,
  NewOriginRequest,
  OriginID,
} from '../types/data';
import {logAAError} from '../log/error';

const apiURL = process.env.GATSBY_API_URL;

export async function getClients(token: string): Promise<ClientList> {
  const req: RequestInit = {
    method: 'GET',
    mode: 'cors',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await handleRequest(`${apiURL}/clients`, req);

  if (!res) {
    logAAError('api request did not work when fetching clients');

    return Promise.reject();
  }

  if (res.status !== 200) {
    logAAError(
      `failed to fetch clients data from api. Code Returned: ${res.status}`
    );

    return Promise.reject();
  }

  return res.json();
}

export async function updateClients(
  data: ClientListPartial,
  token: string
): Promise<void> {
  const req: RequestInit = {
    body: JSON.stringify(data),
    method: 'PATCH',
    mode: 'cors',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const res = await handleRequest(`${apiURL}/clients`, req);

  if (!res) {
    logAAError('api request did not work when updating clients');

    return Promise.reject();
  }

  if (res.status !== 201) {
    logAAError(`failed to update clients. Code Returned: ${res.status}`);

    return Promise.reject();
  }

  return Promise.resolve();
}

export async function deleteClients(
  clientIDs: ClientID[],
  token: string
): Promise<void> {
  const req: RequestInit = {
    body: JSON.stringify(clientIDs),
    method: 'DELETE',
    mode: 'cors',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const res = await handleRequest(`${apiURL}/clients`, req);

  if (!res) {
    logAAError('api request did not work when deleting clients');

    return Promise.reject();
  }

  if (res.status !== 202) {
    logAAError(`failed to delete clients. Code Returned: ${res.status}`);

    return Promise.reject();
  }

  return Promise.resolve();
}

export async function deleteOrigins(
  originIDs: OriginID[],
  token: string
): Promise<void> {
  const req: RequestInit = {
    body: JSON.stringify(originIDs),
    method: 'DELETE',
    mode: 'cors',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const res = await handleRequest(`${apiURL}/origins`, req);

  if (!res) {
    logAAError('api request did not work when deleting origins');

    return Promise.reject();
  }

  if (res.status !== 202) {
    logAAError(`failed to delete origins. Code Returned: ${res.status}`);

    return Promise.reject();
  }

  return Promise.resolve();
}

export async function addClients(
  data: NewClient[],
  token: string
): Promise<void> {
  const req: RequestInit = {
    body: JSON.stringify(data),
    method: 'POST',
    mode: 'cors',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const res = await handleRequest(`${apiURL}/clients`, req);

  if (!res) {
    logAAError('api request did not work when adding new clients');

    return Promise.reject();
  }

  if (res.status !== 201) {
    logAAError(`failed to add new clients. Code Returned: ${res.status}`);

    return Promise.reject();
  }

  return Promise.resolve();
}

export async function addOrigins(
  data: NewOriginRequest[],
  token: string
): Promise<void> {
  const req: RequestInit = {
    body: JSON.stringify(data),
    method: 'POST',
    mode: 'cors',
    redirect: 'follow',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const res = await handleRequest(`${apiURL}/origins`, req);

  if (!res) {
    logAAError('api request did not work when adding new origins');

    return Promise.reject();
  }

  if (res.status !== 201) {
    logAAError(`failed to add new origins. Code Returned: ${res.status}`);

    return Promise.reject();
  }

  return Promise.resolve();
}
