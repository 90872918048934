import styled, {css} from 'styled-components';

const ButtonBase = css`
  padding: ${props => props.theme.sizeScale.sBase}px
    ${props => props.theme.sizeScale.sBase * 3}px;
  font-weight: 600;
  border-radius: ${props => props.theme.sizeScale.sBase * 0.5}px;
  font-family: ${props => props.theme.fonts.font2};
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${props => props.theme.sizeScale.sBase * 0.5}px;

  &:hover {
    cursor: pointer;
  }
`;

const ActionButton = styled.button`
  color: ${props => props.theme.colours.purple11};
  font-weight: 600;
  border: 1px solid ${props => props.theme.colours.purple6};
  background: ${props => props.theme.colours.purple3};

  &:hover {
    background: ${props => props.theme.colours.purple4};
    border: 1px solid ${props => props.theme.colours.purple8};
  }

  ${ButtonBase}
`;

const SecondaryActionButton = styled.button`
  color: ${props => props.theme.colours.mauve12};
  font-weight: 600;
  border: 1px solid ${props => props.theme.colours.mauve6};
  background: ${props => props.theme.colours.mauve3};

  &:hover {
    background: ${props => props.theme.colours.mauve4};
    border: 1px solid ${props => props.theme.colours.mauve8};
  }

  ${ButtonBase}
`;

const DestructiveActionButton = styled.button`
  color: ${props => props.theme.colours.red11};
  font-weight: 600;
  border: 1px solid ${props => props.theme.colours.red6};
  background: ${props => props.theme.colours.red3};

  &:hover {
    background: ${props => props.theme.colours.red4};
    border: 1px solid ${props => props.theme.colours.red8};
  }

  ${ButtonBase}
`;

const PositiveActionButton = styled.button`
  color: ${props => props.theme.colours.green11};
  font-weight: 600;
  border: 1px solid ${props => props.theme.colours.green6};
  background: ${props => props.theme.colours.green3};

  &:hover {
    background: ${props => props.theme.colours.green4};
    border: 1px solid ${props => props.theme.colours.green8};
  }

  ${ButtonBase}
`;

export {
  ActionButton,
  SecondaryActionButton,
  DestructiveActionButton,
  PositiveActionButton,
};
