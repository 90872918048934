import React from 'react';
import styled from 'styled-components';
import {ActionButton, SecondaryActionButton} from './button';
import * as Icons from './icons';

const InstructionsHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TemplateSnippet = styled.pre`
  background: ${props => props.theme.colours.mauveA2};
  padding: ${props => props.theme.sizeScale.sBase}px;
  border: 1px solid ${props => props.theme.colours.mauve8};
`;

const InstructionsDesc = styled.p`
  margin: ${props => props.theme.sizeScale.sBase * 2}px 0;
  font-family: ${props => props.theme.fonts.font2};
`;

const Client = styled.span`
  font-weight: 500;
  color: ${props => props.theme.colours.violet11};
`;

export const AAInstructions: React.FC<{
  clientID: string;
  clientName: string;
  closeInstructions: () => void;
}> = ({clientID, clientName, closeInstructions}) => {
  const insTemplate = `AccessAngel Installation Guide

AccessAngel can be installed on to any website by adding two scripts to the HTML.

This is done slightly differently depending on whether the site has been made with a website builder such as
WordPress, Square Space, Wix or has been built from scratch.


Site built from Scratch

If built from scratch, then the Site administrators need to place the following two scripts into the HTML <head> element.

<script src="https://cdn.jsdelivr.net/npm/@handsfree/aa-launcher@latest/dist/aa-launcher.umd.js"></script>
<script>
  document.addEventListener('DOMContentLoaded', () => {
  aaStart({
    originID: '${clientID}',
    buttonFloatPosition: 'bottom-left',
  });
});
</script>

This position of the widget button can be altered to suit the client’s needs by changing the code
buttonFloatPosition: to either ‘bottom-left’, ‘bottom-right’, ‘top-left’, or ‘’top-right’.


Site built via WordPress

If your site is built with WordPress or a site builder, you may not have direct access to the HTML files.
In order to add AccessAngel to a WordPress site you will need to install a plugin via your admin dashboard first
which gives you access to inject HTML to the site areas.

1. Install the plugin 'Head, Footer and Post Injections' (https://wordpress.org/plugins/header-footer/) to the Wordpress site. Here's a guide for installing
Wordpress plugins: https://www.wpbeginner.com/beginners-guide/step-by-step-guide-to-install-a-wordpress-plugin-for-beginners/
2. From the Wordpress Admin Dashboard, go to Settings > Header and Footer.
3. Add the following code to the 'ON EVERY PAGE' section underneath '<HEAD> PAGE SECTION INJECTION'.
4. Click the ‘save’ button. AccessAngel will now be installed onto the site.


Site built with other Website Builders

Every website builder has its own admin console.

These will all be different but to install AccessAngel onto one of these sites from Square Space, Wix, Go Daddy etc.
it is necessary to find the area in the console where you can inject HTML code to the site. This area may be called
'Custom Code', 'Code Injection' or 'Custom HTML'.

Once you have found this area, it will most likely have a place to input the above code into the 'header', 'head' or
<head> tag of the HTML.
`;

  return (
    <>
      <InstructionsHeader>
        <SecondaryActionButton onClick={closeInstructions}>
          <Icons.Close /> Close
        </SecondaryActionButton>
      </InstructionsHeader>
      <InstructionsDesc>
        Use the Copy button to get the unique instructions to send to{' '}
        <Client>{clientName}</Client>.
      </InstructionsDesc>
      <TemplateSnippet>{insTemplate}</TemplateSnippet>
      <ActionButton onClick={() => navigator.clipboard.writeText(insTemplate)}>
        <Icons.Copy /> Copy
      </ActionButton>
    </>
  );
};
