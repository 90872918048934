import * as React from 'react';
import Header from './header';
import Footer from './footer';
import styled from 'styled-components';

const Layout: React.FC = ({children}) => {
  return (
    <LayoutContainer>
      <Header />
      <main>{children}</main>
      <Footer />
    </LayoutContainer>
  );
};
const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: ${props => props.theme.sizeScale.sBase * 2.25}px;
  font-family: ${props => props.theme.fonts.font2};
  color: ${props => props.theme.colours.mauve12};
`;

export default Layout;
